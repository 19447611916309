.registration-form{
    width: 400px;
    position: fixed;
    z-index: 1;
    margin-left: 60%; 
    top: 75px;
    height: 848px;
  }
  
  
  @media only screen and (max-width: 1500px) {
    .registration-form{
        position: unset;
        margin-top: 50px;
        margin-left: inherit;
        align-self: center;
    }
    .hero-image{
        width: 90%;
    }
  }

  @media only screen and (max-height: 900px) {
    .registration-form{
        position: unset;
        margin-top: 50px;
        margin-left: inherit;
        align-self: center;
    }
    .hero-image{
        width: 90%;
    }
  }


  .promotion{
    text-align: center;
    font-weight: bold;
    padding-bottom: 5px;
    color: saddlebrown;
  }

  