.work-overlay{
  padding-top: 10px;
}

.work-cateogry{
  color: rgb(255, 255, 255); background-color: rgb(0, 0, 0);
  transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1), background-color 1s ease, color 1s ease;
}

.work-heading{
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
  transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1), background-color 1s ease, color 1s ease;
}

.work-overlay:hover .work-cateogry{
  color: rgb(0,0,0);
  background-color: rgb(255,250,106);

}

.work-overlay:hover .work-heading{
  transform: translate3d(0px, -50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;

}

.blog-left{
  background-color: rgba(255, 255, 255, 0);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), background-color 0.6s ease, color 0.6s ease;

}

.blog-category{
  border-color: rgb(0, 0, 0); 
  color: rgb(0, 0, 0); 
  background-color: rgb(198, 209, 224);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), background-color 0.6s ease, color 0.6s ease, border-color 0.6s ease;
}

.blog-heading{
  color: rgb(0, 0, 0);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), color 0.6s ease;
}

.blog-author-and-arrow{
  border-color: rgb(0, 0, 0);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), border-color 0.6s ease;
}

.blog-left:hover{
  background-color: rgba(77,77,77);
}

.blog-left:hover .blog-category{
  border-color: rgb(255,255,255); 
  color: rgb(255,255,255); 
  background-color: rgb(198, 209, 224);
}

.blog-left:hover .blog-heading{
  color: rgb(255,255,255);
}

.blog-left:hover .blog-author-and-arrow{
  border-color: rgb(255, 250, 106);
}

.service-title-ite{
  opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
  transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1),

}

.image-tint {
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.5); /* Red tint with 50% opacity */
  background-blend-mode: multiply;
}

.hero-image{
  width: 70%;
}

.service-flex{
  padding-top: 0px;
}

.service-item-title{
  margin-top: 50px;
}

.service-content{
  display: block;
  margin-left:0px;
}

.service-text{
  background-color: black;
  font-weight: bolder;
  text-align: center;
  margin-right: auto;
  position: unset;
}

.hero-image-div{
  text-align:start;
  margin-left: -5%;
}

.hero-heading{
  line-height: 150%;
  margin-bottom: 0px;
}

.logo-img {
  width: 60px;
  margin-bottom: 4px;
}

.hero-heading-text{
  font-weight: bolder;  background: -webkit-linear-gradient( rgb(65,219,156), rgb(62,204,167), rgb(61,191,175));
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  margin-top: auto;
  margin-bottom: 14px;
  
}

.hero-location-text{
  margin-top: 0px;
  background: -webkit-linear-gradient( black,seagreen, green);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  font-size: larger;
  margin-bottom: 5px;
}

.hero-paragraph{
  font-weight: bolder;  background: -webkit-linear-gradient( black,seagreen, green);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
}

h1 {
  background: -webkit-linear-gradient(green, seagreen, black);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.header-right-side-mobile{ display: none; }
.testimonials-section{ display: none; }
.clients-section{ display: none; }

@media only screen and (max-width: 560px){
  .hero-heading{
    text-align: center;
  }
  .hero-image{
    width: 90%;
  }
  .hero-paragraph{
    text-align: center;
  }
}

@media only screen and (max-width: 720px){
  .header-right-side-mobile{ display: flex; }
  .header-right-side{ display: none; }

}


.blog-author-and-arrow{ width: auto; }

.foot-socials{ display: none;}